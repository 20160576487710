// popups

var servicesToggles, servicesPopup, benefitsToggles, benefitsPopup;

servicesToggles = document.querySelectorAll('.toggle-services');
servicesPopup = document.querySelector('#services');

benefitsToggles = document.querySelectorAll('.toggle-benefits');
benefitsPopup = document.querySelector('#benefits');

if (servicesToggles) {
  Array.prototype.forEach.call(servicesToggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return servicesPopup.classList.toggle('active');
    });
  });
}

if (benefitsToggles) {
  Array.prototype.forEach.call(benefitsToggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return benefitsPopup.classList.toggle('active');
    });
  });
}


// smooth-scroll

import "./components/smooth-scroll.min";

var scroll = new SmoothScroll('a[href*="#"]', {
  offset: 0
} );

// headroom js

import Headroom from "headroom.js";

const headroomAlerts = document.querySelector(".js-alerts");
const headroom = new Headroom(headroomAlerts, {
  "offset": 64,
  "tolerance": 8,
});

headroom.init();
